import React, { ReactNode, useEffect, useState } from "react";
import plus from "../../assets/icons/plus.svg";
import CustomLabel from "../../components/CustomLabel";
import { InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import eyeOff from "../../assets/icons/eye-off.svg";
import eye from "../../assets/icons/eye.svg";
import activate from "../../assets/icons/activate.svg";
import editLetter from "../../assets/icons/edit-letter.svg";
import editPen from "../../assets/icons/edit-pen.svg";
import deleteUser from "../../assets/icons/delete-user.svg";
import CustomizedTables from "../../components/CustomTable";
import { Header } from "../Refund/Refund";
import TableStatus from "../../components/TableStatus";
import ActionMenu from "../../components/ActionMenu";
import UsersNotFound from "./components/UsersNotFound";
import CustomModal from "../../components/CustomModal";
import success from "../../assets/icons/ForgotSuccess.svg";
import {useNavigate} from "react-router-dom";
import {get, post } from "../../config/apiService";
import error from "../../assets/icons/ForgotError.svg";

interface IUser {
    id: string;
    status: ReactNode;
    action: ReactNode;
    actions: ReactNode;
}

const headers: Header[] = [
    { label: 'ID', field: 'id' },
    { label: 'Status', field: 'status' },
    { label: '', field: 'action', align: 'right' },
    { label: '', field: 'actions', align: 'right' },
];

const Users: React.FC = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isChangeIdModalOpen, setIsChangeIdModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [activationType, setActivationType] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [userName, setUsername] = useState<string | null>(null);
    const [errorModal, setErrorModal] = useState<boolean>(false);


    const [users, setUsers] = useState<IUser[]>([]);


    useEffect(() => {
        if(users.length === 0){
            getCompanyUsers()
        }
    }, [users])

    const getCompanyUsers = async () => {
        const companyUsers = await get('/users')
        const companyUsersData = companyUsers.data.map((user: any) => ({
            id: user.username,
            status:user.status ?  <TableStatus key={'1-status'} status="Aktiv" color="#24B07E" /> : <TableStatus key={'0-status'} status="Deaktiv" color="#FF5F57" />,
            action: (
                <button onClick={() => navigate(4234 + '/operations')} key={'1-action'} className="btn px-[16px] py-[10px] rounded-[8px] text-center bg-[#e5f0ec] text-[#24B07E] font-medium text-[15px]">
                    Son əməliyyatları gör
                </button>
            ),
            actions: (
                <ActionMenu key={'1-menu'} children={
                    <ul className="max-w-[200px] w-full">
                        {user.status && (
                            <>
                                <li onClick={() => {
                                    setIsChangeIdModalOpen(true);
                                    setSelectedUserId(user.username);
                                }}
                                    className="hover:bg-[#f5f5f5] transition-all duration-200 cursor-pointer flex items-center gap-2 py-3 px-4">
                                    <img src={editLetter} alt=""/>
                                    <span>ID dəyiş</span>
                                </li>
                                <li onClick={() => {
                                    setIsChangePasswordModalOpen(true)
                                    setSelectedUserId(user.username);
                                }}
                                    className="hover:bg-[#f5f5f5] transition-all duration-200 cursor-pointer flex items-center gap-2 py-3 px-4">
                                    <img src={editPen} alt=""/>
                                    <span>Şifrəni dəyiş</span>
                                </li>
                            </>
                        )}
                        {user.status ?
                            <li onClick={() => handleOpenConfirmation('deactivate', user.username)}
                                className="hover:bg-[#FFF1F0] transition-all duration-200 cursor-pointer flex items-center text-[#FF5F57] gap-2 py-3 px-4">
                                <img src={deleteUser} alt=""/>
                                <span>Deaktiv et</span>
                            </li>
                            :
                                <li onClick={() => handleOpenConfirmation('activate', user.username)}
                                         className="hover:bg-[#f5f5f5] transition-all duration-200 cursor-pointer flex items-center gap-2 py-3 px-4">
                                <img src={activate} alt=""/>
                                <span>Aktiv et</span>
                            </li>}
                    </ul>
                }/>
            ),
        }));
        setUsers([...companyUsersData])
    }


    const handleCreateUser = () => {
        setShowAddUser((prev) => !prev);
        formik.resetForm();
    };

    const handleCloseConfirmation = () => {
        setIsModalOpen(false);
    };

    const changePasswordFormik = useFormik({
        initialValues: {
            newPassword: '',
            newPasswordConfirm: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/, 'Ən azı 1 böyük, 1 kiçik hərf və ən azı 1 rəqəm olmalıdır')
                .required('Required'),
            newPasswordConfirm: Yup.string()
                .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/, 'Ən azı 1 böyük, 1 kiçik hərf və ən azı 1 rəqəm olmalıdır')
                .required('Required'),
        }),
        onSubmit:  async values => {
            handleChangePassowrd()
        },
    })

    const formik = useFormik({
        initialValues: {
            id: '',
            password: '',
        },
        validationSchema: Yup.object({
            id: Yup.string().min(6, "Ən azı 6 simvoldan ibarət olmalıdır (Məs. alimansim)").required('Required'),
            password: Yup.string()
                .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/, 'Ən azı 1 böyük, 1 kiçik hərf və ən azı 1 rəqəm olmalıdır')
                .required('Required'),
        }),
        onSubmit: async values => {
            try {
                const newRegisteredUser = await post('/auth/register', {'username': formik.values.id, 'password':formik.values.password})
                setUsers([])
                setShowAddUser((prev) => !prev);
                formik.resetForm();
            }catch (error){
                console.log(error);
                setErrorModal(true)
                formik.resetForm();
            }

        },
    });

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleOpenConfirmation = async (type: 'activate' | 'deactivate', username: string): Promise<void> => {
        const res = await post('auth/change-user-status', {username, active: type === 'activate' ? true : false});
        setIsModalOpen(true)
        setActivationType(type)
    }

    function closeConfirmation() {
        setIsModalOpen(false)
        setActivationType('')
    }

    function updateUserStatus (activationType: string) {
        setIsModalOpen(false)
        getCompanyUsers()
        setShowSuccessModal(true)
    }

    const handleChangeUserName = async () => {
        console.log('username Change confirmed')
    }

    const handleChangePassowrd = async () => {
        const res = await post('/auth/change-user-passowrd', {'username': selectedUserId, 'password': changePasswordFormik.values.newPassword})
    }

    return (
        <>
            <h4 className="mb-5 text-text-primary text-2xl font-medium">İstifadəçilər</h4>
            <CustomizedTables
                headers={headers}
                notFoundComponent={<UsersNotFound />}
                rows={users as any}
            />
            <button onClick={handleCreateUser} type="button" className="btn btn-primary btn-m w-[200px] ms-auto mt-4">
                <img src={plus} alt="plus_icon"/>
                <span className="ms-2 relative top-[1px]">Yeni istifadəçi yarat</span>
            </button>

            {/*  Add user section  */}
            {showAddUser && (
                <>
                    <form onSubmit={formik.handleSubmit} >
                        <div className="p-8 bg-primary rounded-lg shadow-card mt-5">
                            <h4 className="mb-5 text-text-primary text-xl font-medium">Müştəri məlumatları</h4>
                            <div className="flex justify-start gap-6">
                                <div className="mb-4 w-full max-w-[330px]">
                                    <CustomLabel label={'ID'} required/>
                                    <TextField
                                        fullWidth
                                        id="id"
                                        name="id"
                                        placeholder="ID-ni daxil et"
                                        value={formik.values.id}
                                        onChange={formik.handleChange}
                                        helperText={"Ən azı 6 simvoldan ibarət olmalıdır (Məs. alimansim)"}
                                    />
                                </div>
                                <div className="mb-4 w-full max-w-[330px]">
                                    <CustomLabel label={'Şifrə'} required/>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        placeholder="Şifrəni daxil et"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <button onClick={handleTogglePassword} className="btn">
                                                        {showPassword ? <img src={eyeOff} alt="eye-off"/> : <img src={eye} alt="eye"/>}
                                                    </button>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        helperText={"Ən azı 1 böyük, 1 kiçik hərf və ən azı 1 rəqəm olmalıdır"}
                                    />
                                </div>
                            </div>
                        </div>
                        <button disabled={!formik.isValid || !formik.dirty} type="submit"
                                className="btn btn-primary btn-m w-[200px] ms-auto mt-4">Yarat
                        </button>
                    </form>
                </>
            )}

            <CustomModal
                title="İstifadəçini deaktiv etmək istəyirsiniz?"
                open={isModalOpen}
                hideClose
                width="530px"
                handleClose={handleCloseConfirmation}>
                <div>

                    <p className="text-text-disabled mt-3">
                        {activationType === 'activate' ? 'İstifadəçi aktiv olunarsa, tənzimləmələr bölməsindən yenidən deaktiv edilə bilər' : 'İstifadəçi deaktiv olunarsa, tənzimləmələr bölməsindən yenidən aktiv edilə bilər' }
                    </p>
                    <div className="flex justify-between items-center gap-4">
                        <button onClick={() => updateUserStatus(activationType)} type="button" className="btn btn-primary btn-m btn-full mt-9 !h-[54px]">{activationType === 'activate' ? 'Aktiv' : 'Deaktiv' } et</button>
                        <button onClick={closeConfirmation} type="button" className="btn btn-accent btn-m btn-full mt-9 !h-[54px]">Ləğv et</button>
                    </div>
                </div>
            </CustomModal>

            <CustomModal title="" width="534px" handleClose={() => setShowSuccessModal(false)} open={showSuccessModal}>
                <div>
                    <div className="mb-4 mt-10 text-center">
                        <img className="mx-auto" src={success} alt="forgot_success"/>
                        <h5 className="text-xl font-medium text-[#000000] mt-8">İstifadəçi uğurla {activationType === 'activate' ? 'aktiv' : 'deaktiv'} edildi!</h5>
                        <p className="text-text-disabled">İstifadəçi tənzimləmələr bölməsindən yenidən {activationType === 'activate' ? 'deaktiv' : 'aktiv'} edilə bilər</p>
                    </div>
                    <button onClick={() => setShowSuccessModal(false)} type="button"
                            className="btn btn-primary btn-m btn-full mt-[120px]">Bağla
                    </button>
                </div>
            </CustomModal>
            <CustomModal
                title="ID-ni dəyiş"
                open={isChangeIdModalOpen}
                handleClose={() => setIsChangeIdModalOpen(false)}
                width="550px"
            >
                <div className="p-4">
                    <CustomLabel label="Yeni ID" required />
                    <TextField
                        fullWidth
                        placeholder="ID-ni daxil et"
                        value={userName}
                        onChange={(e) => setUsername(e.target.value)}
                        helperText="Ən azı 6 simvoldan ibarət olmalıdır (Məs. alimansim)"
                    />
                    <button onClick={handleChangeUserName} className="btn btn-primary btn-m btn-full mt-9 !h-[54px]">Yadda saxla</button>
                </div>
            </CustomModal>

            {/* Change Password Modal */}
            <CustomModal
                title="Şifrəni Dəyiş"
                open={isChangePasswordModalOpen}
                handleClose={() => setIsChangePasswordModalOpen(false)}
                width="550px"
            >
                <div className="p-4">
                    <CustomLabel label="Yeni Şifrə" required />
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        placeholder="Yeni şifrəni daxil et"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img
                                        src={showPassword ? eye : eyeOff}
                                        alt="toggle password visibility"
                                        onClick={handleTogglePassword}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        value={changePasswordFormik.values.newPassword}
                        onChange={changePasswordFormik.handleChange}
                        helperText="Ən azı 1 böyük, 1 kiçik hərf və ən azı 1 rəqəm olmalıdır"
                    />
                    <div className="my-2"></div>
                    <CustomLabel label="Yeni Şifrə Təsdiqi" required/>
                    <TextField
                        fullWidth
                        id="newPasswordConfirm"
                        name="newPasswordConfirm"
                        placeholder="Yeni şifrənin təkrarını daxil et"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img
                                        src={showPassword ? eye : eyeOff}
                                        alt="toggle password visibility"
                                        onClick={handleTogglePassword}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        value={changePasswordFormik.values.newPasswordConfirm}
                        onChange={changePasswordFormik.handleChange}
                    />
                    <button type='submit' onClick={handleChangePassowrd} className="btn btn-primary btn-m btn-full mt-9 !h-[54px]">Yadda saxla</button>
                </div>
            </CustomModal>
            <CustomModal title="" width="534px" handleClose={() => setErrorModal(false)} open={errorModal}>
                <div>
                    <div className="mb-4 mt-10 text-center">
                        <img className="mx-auto" src={error} alt="forgot_success"/>
                        <h5 className="text-xl font-medium text-[#000000] mt-8">İstifadəçi yaradarkən xəta baş verdi!</h5>
                        <p className="text-text-disabled">Zəhmət olmasa yenidən yoxlayın</p>
                    </div>
                    <button onClick={() => setErrorModal(false)} type="button"
                            className="btn btn-primary btn-m btn-full mt-[120px]">Bağla
                    </button>
                </div>
            </CustomModal>
        </>
    );
};

export default Users;
