import React, {useEffect, useState} from "react";
import CustomizedTables from "../../components/CustomTable";
import { get } from "../../config/apiService";


interface Header {
    label?: string;
    field: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

const headers: Header[] = [
    { label: 'Taksit müddəti', field: 'duration' },
    { label: 'Faiz dərəcəsi', field: 'percent' },
];
const rows = [
    {
        duration: "6 ay",
        percent: "6%",
    },
    {
        duration: "8 ay",
        percent: "6%",
    },
    {
        duration: "10 ay",
        percent: "6%",
    },
    {
        duration: "12 ay",
        percent: "6%",
    },
    {
        duration: "16 ay",
        percent: "6%",
    },
];

const Rates: React.FC<any> = () => {

    const [tariffsData, setTariffs] = useState<any[]>([])

    useEffect(() => {
        if(tariffsData.length === 0){
            getCompanyTariffs()
        }
    }, [tariffsData]);

    const getCompanyTariffs = async () => {
        const merchantId = localStorage.getItem("merchantId");
        const data = await get(`/installment/partner-tariffs?merchantId=${merchantId}`)
        const tariffs = data.data.partnerTariffsInfo.map((tariff: any) => ({
            duration: tariff.installmentCount,
            percent: tariff.interestRate,
        }))
        setTariffs([...tariffsData, ...tariffs])
    }
    return (
        <>
            <h4 className="mb-5 text-text-primary text-2xl font-medium">Tariflər</h4>
            <CustomizedTables name="" headers={headers} rows={tariffsData} />
        </>
    )
}

export default Rates
