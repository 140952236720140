import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { getTheme } from "./styles/theme";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import PublicPage from "./features/PublicPage/PublicPage";
import PrivateRoute from "./security/PrivateRoute";
import PrivatePage from "./features/PrivatePage/PrivatePage";
import UiComponents from "./features/UiComponents/UiComponents";
import Layout from "./containers/Layout";
import Login from "./features/Auth/Login";
import LoginLayout from "./containers/LoginLayout";
import ChangePassword from "./features/Auth/ChangePassword";
import Installment from "./features/İnstallment/Installment";
import Refund from "./features/Refund/Refund";
import Reports from "./features/Reports/Reports";
import Users from "./features/Users/Users";
import Rates from "./features/Rates/Rates";
import UserOperations from "./features/Users/UserOperations";

function App() {
  const [theme, setTheme] = useState(createTheme(getTheme()));

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setTheme(createTheme(getTheme()));
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              {/* Public routes for Login and Change Password */}
              <Route element={<LoginLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/change-password" element={<ChangePassword />} />
              </Route>

              {/* Protected routes - everything under this layout requires authentication */}
              <Route
                  element={
                    <PrivateRoute>
                      <Layout />
                    </PrivateRoute>
                  }
              >
                <Route path="/" element={<Navigate to="/taksit" replace />} />
                <Route path="/taksit" element={<Installment />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/public/*" element={<PublicPage />} />
                <Route path="/dev" element={<UiComponents />} />
                <Route path="/private" element={<PrivatePage />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:id/operations" element={<UserOperations />} />
                <Route path="/rates" element={<Rates />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default App;
